/*Menu.css*/
.no-scroll {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}
@media screen and (max-width: 768px) {
  .menu-outer-container {
    height: 95px; /* Set the height of the menu */
  }

  .menu-container {
    align-items: center; /* Center items vertically */
    padding-left: 20px; /* Space from the left edge */
    padding-right: 20px; /* Space from the right edge */
    max-height: 95px !important;
    margin-top: 0;
    padding-top: 0;
    position: relative;
    z-index: 2;
    height: 100%;
  }

  .menu-logo {
    width: 104px; /* Increased width of the logo */
    height: 58.5px; /* Increased height of the logo */
    margin-right: 0; /* Reset margin-right */
  }

  .menu-toggle {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around; /* Evenly space the lines */
    padding: 0;
    font-family: 'Source Sans Pro';
    font-weight: 100;
    font-size: 48px;
    color: #4663AB;
  }

  .menu-toggle::before,
  .menu-toggle::after,
  .menu-toggle span {
    display: block;
    content: '';
    height: 4px; /* Height of each line */
    background-color: white;
    color: #4663AB;
  }

  .menu-toggle span {
    opacity: 0; /* Hide the middle span */
  }

  .menu-close-button {
    display: none; /* Hide the close button by default */
  }

  .menu-links {
    display: none; /* Hide the menu links initially */
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px; /* Space from the top when menu is open */
    max-height: fit-content;
  }

  .menu-links.open {
    display: flex; /* Display the menu when open */
    position: fixed; /* Fix the position relative to the viewport */
    top: 0; /* Position from the top of the viewport */
    right: 0; /* Align to the right side */
    width: 300px; /* Set the width of the menu */
    min-height: -webkit-fill-available;
    min-height: 100dvh; /* Full height of the viewport */
    background-color: #ffffff; /* Set the background color */
    z-index: 2000; /* High z-index to ensure it's on top */
    flex-direction: column; /* Stack menu items vertically */
    justify-content: flex-end; /* Align content to the top */
    padding-top: 0px; /* Offset by the height of the menu bar */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    transition: transform 0.3s ease-in-out; /* Smooth transition for sliding */
  }

  .menu-links.open .menu-close-button {
    display: block; /* Show the close button when menu is open */
    margin-left: auto; /* Align the close button to the right */
    margin-right: 20px; /* Space from the right edge */
    font-size: 1.5rem; /* Size of the close button */
    background: none;
    border: none;
  }

  .menu-close-button {
    display: block; /* Show the close button */
    position: absolute; /* Position it within the menu-links */
    top: 20px; /* Space from the top */
    right: 20px; /* Space from the right */
    background: none;
    border: none;
    font-size: 24px; /* Size of the close button */
    cursor: pointer;
  }

  /* Ensure the submenu is always visible and slides in from the right */
  .user-initial-container {
  	position: fixed; /* Fix the position relative to the viewport */
    top: 40px; /* Position from the top of the viewport */
    right: 110px; /* Align to the right side */
    width: auto;
    height: auto;
    z-index: 2002; /* Higher z-index to ensure it's above the slide panel menu */
  }

  .user-greeting {
  	color: #000000;
  	margin-left: 0px;
  }

  .user-greeting.fade-in {
  	margin-left: 0px;
  }

  .submenu-link {
  	padding-left: 0;
  	color: #D13C8D;
  	font-weight: 600;
  }

  .menu-links {
  	width: 170px;
  }

  .menu-link {
  	font-size: 14px;
  	width: 170px;
  	margin: 10px;
  	padding: 0;
  }

  .menu-link.message-link {
  	display: none;
  }

  .post-item-link {
  	position: fixed;
  	top: 350px;
  	padding: 5px;
  }

  .user-expanded-submenu {
  	border: 0;
    position: fixed; /* Fix the position relative to the viewport */
    top: 80px; /* Position from the top of the viewport */
    right: 80px; /* Align to the right side */
    width: 170px; /* Set the width of the submenu */
    background-color: #ffffff; /* Set the background color */
    z-index: 2001; /* Higher z-index to ensure it's above the slide panel menu */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Align content to the top */
    transition: transform 0.3s ease-in-out; /* Smooth transition for sliding */
    
  }

  .menu-close-button {
  	font-family: 'Source Sans Pro';
  	z-index: 2002; /* Higher z-index to ensure it's above the slide panel menu */
  	color: #4663AB;
  	font-size: 42px !important;
  	font-weight: 100 !important;
  	position: fixed !important;
  	top: 25px !important;
  	right: 0 !important;
  }

}

@media only screen and (max-width: 767px){ 
    .menu-link:nth-child(7) {
      margin-bottom: 0 !important;
    }
    .menu-link-separator {
      display: block;
      height: 1px solid;
    }
    .menu-link {
      font-size: 14px;
      width: 170px;
      margin: 10px 10px;
      padding: 0;
    }
}
