/* src/components/OportunidadesDemandas.css */
.content-area {
  max-width: 1280px;
  margin: 0 auto; /* Center the content */
  padding: 0 20px; /* Horizontal padding */
  text-align: center; /* Center-align text */
}

.title-area {
  grid-column: 1 / -1; /* Make the title area span all columns */
  text-align: center; /* Align title and paragraph to the center */
  margin-bottom: 40px; /* Space between this section and the next */
}

.oportunidades-demandas h1 {
  font-family: 'Source Sans Pro', sans-serif;
}

.oportunidades-demandas p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px; /* Space below the paragraph */
}

.icon-grid {
  display: grid;
  grid-column: 1 / -1; /* Make the title area span all columns */
  grid-template-columns: repeat(5, 1fr); /* Five columns for icons */
  row-gap: 20px; /* Space between rows */
  column-gap: 16px; /* Space between columns */
  align-items: center; /* Center-align icons and descriptions */
  justify-content: center; /* Centralize the grid within its container */
  margin-bottom: 100px;
}

.icon-column {
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center; /* Center-align content within each column */
  text-align: center; /* Center-align text */
  margin-bottom: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.icon-column img {
  width: 100px; /* Adjust as needed */
  height: auto;
  margin-bottom: 10px; /* Space between icon and description */
}

@media screen and (max-width: 768px) {
  .oportunidades-demandas {
    background-image: none; /* Remove background image on smaller screens */
    padding: 20px 10px !important; /* Adjusted padding */
  }

  .content-area {
    padding: 0 10px !important; /* Adjusted horizontal padding */
  }

  .oportunidades-demandas .title-area h1 {
    font-size: 22px !important; /* Adjusted font size */
    font-weight: bold !important;
    line-height: 32px !important;
    width: 316px !important;
    max-width: 316px !important;
    margin-bottom: 20px !important;
    font-family: 'Source Sans Pro', sans-serif !important;
  }

  .oportunidades-demandas .title-area p {
    font-size: 16px !important; /* Adjusted font size */
    font-weight: bold !important;
    width: 346px !important;
    max-width: 346px !important;
    margin-bottom: 40px !important;
    font-family: 'Source Sans Pro', sans-serif !important;
  }

  .icon-grid {
    grid-template-columns: repeat(3, 1fr) !important; /* Three columns for icons */
    row-gap: 30px !important; /* Increased space between rows */
    margin-bottom: 0 !important;
  }

  .icon-column:last-child {
    grid-column: 1 / span 3; /* Span across all three columns */
    justify-content: center; /* Center the content */
  }

  .icon-column {
    font-size: 14px !important; /* Reduced font size */
  }

  .icon-column img {
    width: 80px !important; /* Adjusted icon size */
  }
}