/* src/components/ParceirosDaRede.css */
.parceiros-da-rede {
  background-color: #FFFFFF; /* White background */
  padding: 40px 0; /* Vertical padding */
}

.parceiros-da-rede .parceiros-content-area {
  max-width: 1280px;
  margin: 0 auto; /* Center the content */
  padding: 20px;
  text-align: center; /* Center-align text */
}

.parceiros-da-rede .title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #404040; /* Dark grey text */
  margin-top: 20px;
  margin-bottom: -20px; /* Space below the title */
}

.logos-area {
  display: flex;
  justify-content: center; /* Center-align logos horizontally */
  flex-wrap: wrap; /* Allow logos to wrap onto multiple lines */
  align-items: center; /* Center-align logos vertically */
  gap: 40px; /* Space between logos */
}

.logos-area a {
  display: block;
  float: left;
  width: 100px;
  margin-right: 50px;
}

.logos-area a:last-child {
  margin-right: 0;
}

.logos-area img {
  max-width: 100%; /* Ensure logos fit within the column */
  height: auto; /* Maintain aspect ratio */
}

@media screen and (max-width: 768px) {
  .parceiros-da-rede .title {
    font-size: 24px; /* Adjusted title font size for smaller screens */
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .logos-area {
    justify-content: space-around; /* Space out logos evenly */
    flex-wrap: wrap; /* Allow wrapping */
  }

  .logos-area a {
    width: auto; /* Auto width for flexibility */
    margin: 10px; /* Reduced margin for compact layout */
  }

  .logos-area img {
    max-width: 80px; /* Reduced logo size for smaller screens */
    height: auto; /* Maintain aspect ratio */
  }
}
