/* src/pages/Blog.css */
.blog-page-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) inset;
}

.blog-main-content {
    display: flex;
    justify-content: space-between;
}


.blog-center-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
}

.blog-row-container {
  font-family: 'Source Sans Pro';
  width: 100%;
  clear: both;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}


.blog-content-container {
    flex: 1;
    padding: 30px 0;
    width: 665px;
}


.blog-card {
  display: block;
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
  width: 325px !important;
  height: 435px;
  border-radius: 5px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  cursor: pointer; /* Change cursor to pointer */
}


.blog-card-media {
  height: 200px;
  background-size: cover;
  background-position: center;
}

.blog-card-content {
  background-color: #F4F4F5;
  padding: 30px;
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-card-category-date {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-family: 'Source Sans Pro';
  color: #404040;
}

.blog-card-category-date .category {
  text-transform: capitalize;
}

.blog-card-category-date .date {
  text-transform: uppercase;
}

.category {
  background-color: #D13C8D;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
}

.blog-card-title {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Source Sans Pro';
}

.read-more-link {
  text-decoration: none;
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: 60px;
  color: #4663AB;
  font-family: 'Source Sans Pro';
  font-size: 13px;
}

.read-more-link:hover {
  text-decoration: underline;
}

/* Style for the sidebar and its components */
.blog-sidebar {
  width: 283px; /* Sidebar width */
  border: 0;
}

.blog-categories-box {
  background-color: #FFF;
  padding: 15px;
  border: 1px solid #D4D4D4;
  border-radius: 10px;
  margin-bottom: 20px; /* Space between boxes */
}

.blog-search-box {
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
}

.blog-search-box form {
  display: flex;
}

.blog-search-box input {
  flex-grow: 1;
  padding: 10px;
  height: 30px;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  border: 1px solid #D4D4D4;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.blog-search-box input:focus {
    outline-width: 0;
}

.blog-search-box button {
  background: url('../assets/input-search.png') no-repeat center center;
  width: 52px;
  height: 52px;
  border: none;
  cursor: pointer;
  background-size: 52px 52px; /* Ensure the image covers the entire button area */
}

.blog-categories-box {
  padding-left: 30px;
}

.blog-categories-box h3 {
  margin-top: 0;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: bold;
  color: #404040;
}

.blog-categories-box ul {
  list-style: none;
  padding: 0;
}

.blog-categories-box ul li {
  cursor: pointer;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.blog-categories-box li {
  cursor: pointer;
  color: #D13C8D;
  font-family: 'Source Sans Pro';
  font-size: 14px;
}

.blog-categories-box li::before {
  content: '>';
  margin-right: 5px; /* Adjust the spacing as needed */
  /* Add additional styling if needed */
}


/* Adjust the blog-content-container to accommodate the sidebar */
.blog-content-container {
  float: left;
  max-width: 100%;
  min-width: 665px;
}

.newsletter {
  background-color: #FFD62C;
  height: 228px;
  background-image: url('../assets/bg-blogo-newsletter.svg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter-container {
    background-color: #FFD62C;
    height: 228px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.newsletter-container h2 {
    color: #5D215C;
    font-size: 19px;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    margin-bottom: 20px;
}

.newsletter-container form {
    display: flex;
}

.newsletter-container button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-color: #4663AB;
    color: white;
    cursor: pointer;
    height: 52px;
}

.success {
  font-family: 'Source Sans Pro';
  font-weight: bold;
  margin-bottom: 20px;
  color: green;
}

.blog-post-column {
  max-width: 800px;
  padding: 50px;
  float: left;
}

.blog-post-column img {
  max-width: 800px;
  margin: 20px 0;
}

.blog-post-column h1 {
  font-family: 'Source Sans Pro';
  font-size: 40px;
  font-weight: bold;
  color: #5E205D;
}

.blog-post-column p {
  font-family: 'Source Sans Pro';
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  color: #404040;
  margin-bottom: 1.5rem;
}

.blog-post-column h5 {
  position: relative;
  display: block;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  line-height: 30px;
  color: #5E205D !important;
  max-width: 494px;
  padding-left: 90px !important;
}

.blog-post-column h5::before {
  position: absolute;
  left: 0;
  top: 0;
  background-image: url('../assets/img-quote.svg'); /* Path to your image */
  background-size: 69px 62px; /* Set the size of your background image */
  background-repeat: no-repeat;
  font-family: 'Source Sans Pro';
  font-size: 180px; /* You might not need this line if you're using an image */
  width: 69px; /* Width of your image */
  height: 62px; /* Height of your image */
  color: #34A853; /* This color will not affect the image */
  content: ''; /* Necessary for the pseudo-element to display */
}


.blog-post-column h6 {
  padding-left: 90px;
  margin-top: 10px;
}