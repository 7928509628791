/* src/components/AdsWall.css */
.ads-wall-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 60px;
}

.user-initials-avatar {
  border-radius: 50%;
  background-color: #D13C8D;
  width: 38px;
  height: 38px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}


.load-more {
  width: 140px;
  height: 44px;
  border: 1px solid #4563AB;
  border-radius: 50px; /* Creates highly rounded corners */
  background-color: #ffffff;
  color: #4563AB;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  cursor: pointer;
  margin: 50px auto;
  display: block;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-container .header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.header-container .header-message-container {
  display: flex;
  align-items: center;
}

.header-container .info-icon {
  margin-top: 20px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}


.header-message {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  color: #404040;
  margin-left: 10px; /* Adjust as needed */
  margin-top: 45px;
  width: 340px;
}

.post-link {
  font-weight: bold;
  color: #4563AB;
  cursor: pointer;
  text-decoration: underline;
}

.post-link:hover {
  text-decoration: none;
}

.load-more:hover {
  background-color: #4563AB; /* Change background color on hover */
  color: #ffffff; /* Change text color on hover */
}

.ads-wall-grid {
  display: grid;
  grid-gap: 15px;
  margin-top: 40px;
}

.ads-wall-item {
  grid-column: span 6;
  background-color: #ffffff;
  border-radius: 15px;
  height: 270px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative; /* Needed for absolute positioning of children */
  padding-top: 90px;
}

.item-header,
.item-category {
  position: absolute; /* Position your elements absolutely within the .ads-wall-item */
  top: 20px; /* Adjust the value to align with the top padding of .ads-wall-item */
}

.item-header {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  left: 20px;
  height: 38px;
  border-top-left-radius: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.item-header.demand {
  background-color: #D13C8D;
}

.item-header.offer {
  background-color: #34A853;
}

.item-user-info {
  margin-bottom: 0 !important;
}

.item-name, .item-location, .item-location-details {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 100;
  color: #A3A3A3;
}

.item-main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-title {
  width: 200px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #5E205D;
  /* No change needed here unless you need to adjust margins */
}

.item-location-details {
  display: flex;
  align-items: center;
}

.mural-location-icon {
  width: 8px !important; /* Or your preferred size */
  height: 11px !important; /* Or your preferred size */
  margin: 0 2px; /* Adjust space between text and icon */
}

.item-distance {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 100;
  color: #A3A3A3;
  margin-left: 4px; /* Space after the icon */
}

.item-category {
  display: block;
  float: right;
  color: #4663AB;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Source Sans Pro';
  text-transform: capitalize;
  right: 20px;
  height: 28px !important;
  margin-top: 10px;
}

.item-category img {
  margin-top: 0;
  width: 28px;
  height: 28px;
  padding: 0;
}

.font-white {
  color: #ffffff !important;
  padding: 15px 30px;
  margin-bottom: 50px !important;
}

.mb-50 {
  margin-bottom: 25px !important;
}

.wall-category-icon {
  margin-right: 8px; /* Adjust as needed */
  vertical-align: middle; /* Aligns icon with text */
  background-color: #4663AB;
  border-radius: 50%;
  padding: 10px; /* Adjust padding as necessary */
  display: inline-block; /* Ensures the background applies correctly */
  /* Add additional styling for positioning, size, etc., as needed */
  width: 28px;
  height: 28px;
}

.wall-category-icon img {
  padding: 0 !important;
}

.item-user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.item-user-info img {
  border-radius: 50%;
  margin-right: 10px;
  width: 38px;
  height: 38px;
}

.item-user-info div {
  margin-right: 5px;
}

.item-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #5E205D;
  margin-top: 10px; /* Adjust margin for spacing */
}

.item-description {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: ;
  color: #404040;
  margin-top: 10px;
}

.item-actions {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 25px;
  width: 100%;
}



.item-actions button.responder {
  width: 147px;
  height: 44px;
  border: 1px solid #4563AB;
  border-radius: 50px; /* Creates highly rounded corners */
  background-color: #ffffff;
  color: #4563AB;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0; /* Adjust padding as needed */
  margin-left: -120px !important;
  margin-top: 0 !important;
}


.item-actions button.heart-button {
  background-color: #FF6584; /* Adjust based on design */
  margin-top: 0 !important;
}


.heart-button {
  cursor: pointer;
  background-color: transparent !important;
  border: 0;
  width: 24px;
  height: 22px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px !important;
  padding-right: 25px !important;
}


.heart-icon {
  position: absolute;
  font-size: 24px;
  color: #D13C8D; /* Default color for unfilled heart */
  right: 40px;
}

.highlights-button {
  margin-top: 100px !important;
}

.suggested-items-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #5E205D;
  margin-bottom: 20px; /* Adjust the margin as necessary */
}

.horizontal-rule {
  margin-top: 75px;
  margin-bottom: 50px;
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #C4C4C4;  /* old IE */
  background-color: #C4C4C4;  /* Modern Browsers */
}

.message-icon { 
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

/* Add this to your AdsWall.css */
.row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; /* Adjust as needed */
}

.back-to-mural-link {
  font-family: 'Source Sans Pro';
  font-weight: bold;
  font-size: 14px; /* Adjust as needed */
  color: #4663AB; /* Adjust as needed */
  text-decoration: none; /* Removes underline from links */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.leia-mais-button {
  display: flex;
  width: 99px;
  height: 44px;
  border: 1px solid #4563AB;
  border-radius: 50px; /* Creates highly rounded corners */
  background-color: #ffffff;
  color: #4563AB;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0; /* Adjust padding as needed */
  margin-top: 0 !important;
}



.leia-mais-button:hover {
  background-color: #4563AB; /* Change background color on hover */
  color: #ffffff; /* Change text color on hover */
}

/* Responsive design for desktop */
@media (min-width: 768px) { /* Adjust breakpoint as needed */
  .ads-wall-grid {
    grid-template-columns: repeat(9, 1fr); /* Six columns, since each item spans two columns */
  }
  .ads-wall-item {
    grid-column: span 3; /* Each item spans half of the grid on larger screens */
  }
}

/* Responsive design for mobile */
@media (max-width: 767px) { /* Adjust breakpoint as needed */

  .force-width {
    width: 210px !important;
  }
  .force-width span {
    width: 210px !important;
  }
  .ads-wall-container {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  #root {
    height: auto !important;
    overflow-y: auto !important;
  }

  .ads-wall-grid {
    grid-template-columns: 1fr; /* Single column for mobile */
    grid-gap: 10px; /* Adjust gap between items */
  }


  .item-actions button.responder {
    margin-left: -20px !important;
    width: 140px !important;
    max-width: 140px !important;
  }

  .ads-wall-item {
    /* Set the card height and change display to flex to control the layout */
    height: 356px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will spread out the child elements */
    padding-top: 20px; /* Adjust top padding */
  }

  .header-container {
    display: block !important;/* Stack elements vertically */
    width: 100% !important;
  }

  .header-message-container {
    display: block !important;/* Stack elements vertically */
    width: 100% !important;
  }

  .info-icon {
    display: block;
    float: left;
    width: 30px !important;
    margin: 10px 0; /* Adjust margin for info icon */
    margin-right: 10px !important;
  }

  .header-message {
    margin-top: 10px; /* Adjust top margin */
    width: auto; /* Adjust width to fit content */
  }

  
  .item-main-content {
    /* This ensures that the title takes the full width of its container */
    display: block;
  }

  .item-title {
    /* The title will naturally take its own row in a block display */
    width: 100%; /* Use the full width of the container */
    margin-bottom: 10px; /* Add some space below the title */
  }

  .item-user-info {
    margin-top: 65px !important;
    margin-bottom: 0 !important;
  }

  .item-location-details {
    /* Now location details will also take its own row */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px; /* Space between title and this row */
  }

  .item-actions {
    /* Adjust the positioning of the action buttons */
    position: static; /* Remove absolute positioning on mobile */
    margin-top: auto; /* Pushes the actions to the bottom of the container */
  }

  .mural-location-icon {
    margin-right: 5px; /* Adjust space between text and icon */
  }

  .weekly-highlights-internal {
    height: auto !important;
    overflow-y: auto !important;
  }

}
