/* src/components/AgoraEComVoce.css */
.agora-e-com-voce {
  background-color: #F47D24; /* Orange background */
  padding: 100px 0; /* Vertical padding */
  text-align: center; /* Center-align text */
  background-image: url('../assets/bg-agora-e-com-vc.svg'); /* Update the path to your image */
  background-size: 2338px 1153px;
  background-repeat: no-repeat;
  background-position: center -80px; /* Adjust as per your design requirements */
}

.content-area {
  max-width: 1280px;
  margin: 0 auto; /* Center the content */
  padding: 0 20px; /* Horizontal padding */
}

.text-white-700 {
  color: #ffffff;
}

.title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff; /* White text */
  margin-top: 80px;
  margin-bottom: 60px; /* Space below the title */
}


@media screen and (max-width: 768px) {
  .agora-e-com-voce {
    padding: 60px 0; /* Reduced vertical padding for smaller screens */
    background-size: cover; /* Adjust background image to cover the area */
    background-position: center; /* Center background image */
  }

  .content-area {
    padding: 0 10px; /* Adjusted horizontal padding for smaller screens */
  }

  .title {
    font-size: 24px; /* Smaller font size for better readability */
    margin-top: 40px; /* Adjusted top margin */
    margin-bottom: 30px; /* Adjusted bottom margin */
  }

  .join-us-button {
    width: 200px !important; /* Full-width button for better accessibility */
    padding: 10px; /* Adjust padding for the button */
  }
}
