/* src/components/CategoryFilter.css */
.category-filter-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 50px;
  margin-bottom: 20px; /* Adjust based on layout */
  padding: 0 20px; /* Padding on the sides */
}

.category-filter-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize; /* Capitalize category names */
  margin-right: 35px;
}

.category-filter-container > div:last-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize; /* Capitalize category names */
  margin-right: 0px;
}

.category-filter-container img {
  width: 72px; /* Icon size, adjust as needed */
  height: 72px; /* Icon size, adjust as needed */
  margin-bottom: 8px;
  background-color: #4663AB;
  border-radius: 50%;
}

.category-filter-container span {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px; /* Adjust font size as needed */
  font-weight: bold;
  color: #4663AB; /* Text color for category names */
}

/* Styles for active category */
.category-filter-container .active img {
  filter: none; /* Full color for the active category icon */
}

.category-filter-container .active span {
  color: #4563AB; /* Text color for the active category name */
}

/* Hover effect */
.category-filter-container > div:hover img {
  filter: none; /* Full color on hover */
}

.category-filter-container > div:hover span {
  color: #4563AB; /* Text color on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .category-filter-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; /* Allows horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
    gap: 10px;
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 0; /* Resets any padding */
    scroll-padding-left: 20px; /* Ensures padding when scrolling to the start */
    scroll-snap-type: x proximity; /* Optional: enables snapping to elements when scrolling */
  }

  .category-filter-container > div {
    scroll-snap-align: start; /* Aligns the items to the start when snapping */
    flex: 0 0 auto; /* Prevents flex items from growing or shrinking */
    margin-right: 10px;
    margin-left: 10px; /* Adds spacing on the left side of the items */
  }

  .category-filter-container > div:first-child {
    margin-left: 670px; /* Adds additional spacing for the first item */
  }

  .category-filter-container > div:last-child {
    margin-right: 20px; /* Adds additional spacing for the last item */
  }

  .category-filter-container img {
    width: 60px; /* Adjusts size for mobile */
    height: 60px; /* Adjusts size for mobile */
    margin-bottom: 8px; /* Space below the image */
  }

  .category-filter-container span {
    font-size: 12px; /* Adjusts font size for mobile */
  }

  /* Hide the scrollbar for a cleaner look */
  .category-filter-container::-webkit-scrollbar {
    display: none;
  }
}