/* Entrepreneurs.css */
.weekly-highlights {
  background-color: #FED62C;
  padding: 20px 0 60px 0; /* Vertical padding */
  background-image: url('../assets/bg-vitrine-empreendedores.svg'); /* Update the path to your image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; /* Adjust as per your design requirements */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 780px;
}

.weekly-highlights-internal {
  background-color: #FED62C;
  padding: 20px 0 60px 0; /* Vertical padding */
  background-image: url('../assets/bg-vitrine-empreendedores.svg'); /* Update the path to your image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; /* Adjust as per your design requirements */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 850px;
}

.highlights-container {
  display: block;
  width: 1280px;
  height: 615px;
  margin: 0 auto;
  justify-content: space-around; /* Distribute space within the container */
  align-items: center; /* Center items horizontally */
  /* Add other styles if needed */
}

.highlights-grid {
  display: flex; /* Use flexbox instead of block */
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: flex-start; /* Align items to the start */
  align-items: flex-start; /* Align items to the start */
  width: 1280px; /* Full width */
  margin-top: 40px;
}

.highlights-button {
  display: block !important;
  margin: 0 auto !important;
  margin-top: 25px !important;
}

.highlights-container h2 {
  color: #404040;
  margin-bottom: 0px;
}

.vitrine-de-empreendedores {
  display: block;
  width: 480px;
  font-size: 40px;
  margin: 20px auto;
}

.highlights-container .call-to-action {
  display: block;
  width: 1280px;
}

.entrepreneurs-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
}

.entrepreneur-card {
  float: left;
  display: block;
  margin-right: 30px;
  flex-direction: column;
  justify-content: space-between;
  width: 284px;
  height: 351px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 30px; /* Adjusted margin-bottom */
}

.entrepreneur-card p {
  max-width: 280px;
}

.font-white {
  font-family: 'Source Sans Pro', sans-serif;
  color: #ffffff;
}

.entrepreneurs-grid {
  display: flex; /* Use flexbox instead of block */
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: flex-start; /* Align items to the start */
  align-items: flex-start; /* Align items to the start */
  width: 1280px; /* Full width */
  margin-top: 40px;

}

.entrepreneur-card h2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #5E205D;
}

.entrepreneur-card h3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  height: 14px;
  margin: 0;
  margin-top: 15px;
  padding: 0;
  font-weight: bold;
  color: #EE7911;
}

.location-container {
  display: flex;
  align-items: center; /* Center the icon with the text */
}

.location-icon {
  margin-right: 2px; /* Space between the icon and the text */
  width: 18px; /* Adjust size as needed */
  height: 18px; /* Adjust size as needed */
  margin-top: 12px;
}

.entrepreneur-card h4 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  font-size: 14px;
  height: 14px;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  color: #404040;
}

.entrepreneur-user-info {
  display: flex;
  align-items: center;
  height: 35px;
  margin: 0;
  margin-top: 15px;
  padding: 0;
}

.entrepreneur-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.entrepreneur-card h5 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #A3A3A3;
  margin: 0;
  padding: 0;
}

.entrepreneur-card p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #404040;
  margin: 0;
  margin-top: 25px;
  padding: 0;
}

.entrepreneur-contact a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #4663AB;
  text-decoration: none;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 0px;
  padding: 0;
  display: flex;
  align-items: center;
}

.entrepreneur-contact a img {
  margin-right: 10px;
}

.entrepreneur-contact a.website {
  background-image: url('../assets/ico-url-perfil.svg');
  background-repeat: no-repeat;
  background-position: left center;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 0;
  padding-left: 25px;
}

.entrepreneur-contact a.instagram {
  display: block;
  background-image: url('../assets/ico-instagram-perfil.svg');
  background-repeat: no-repeat;
  background-position: left center;
  margin: 0;
  padding: 0;
  padding-left: 25px;
}

.entrepreneur-contact .phone {
  background-image: url('../assets/ico-telefone-perfil.svg');
  background-repeat: no-repeat;
  background-position: left center;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
  padding-left: 25px;
}

.entrepreneur-button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin: 0 auto;
  margin-top: 30px;
}

/* General mobile adjustments */
@media screen and (max-width: 768px) {
  .weekly-highlights {
    padding: 20px 0; /* Adjust the vertical padding */
    background-size: cover; /* Ensure the background covers the area */
    background-position: center; /* Center the background */
    padding-bottom: 60px !important;
    height: auto !important;
  }

  .weekly-highlights-internal {
    height: auto !important;
  }

  .highlights-container {
    width: auto; /* Allow the container to fit the screen size */
    height: auto; /* Height adjusts to content */
    padding: 0 15px; /* Add padding on the sides */
  }

  .highlights-container h1,
  .highlights-container h2,
  .vitrine-de-empreendedores {
    font-size: 24px; /* Adjust font size */
    margin: 10px 0; /* Adjust top and bottom margins */
    width: auto; /* Allow width to adjust */
    text-align: center; /* Center align text */
  }

  .highlights-container h2 {
    width: 270px !important;
    margin: 0 auto;
  }

  .highlights-grid {
    flex-direction: column; /* Stack cards vertically */
    padding: 0;
    flex-wrap: nowrap;
    padding: 0 25px;
    width: calc(100% - 40px);
  }

  .weekly-highlights .join-us-button, .highlights-button {
    font-size: 16px !important;
    width: 310px !important;
    max-width: 310px !important;
    margin-top: 20px; /* Adjust space above the button */
    margin-bottom: 40px !important;
  }

  .entrepreneurs-grid {
    /* This will stack cards vertically */
    display: block; /* Change from flex to block */
    padding: 0;
    width: 100%;
  }

  .entrepreneur-card {
    min-width: calc(100% - 40px) !important; /* Set to full width */
    max-width: calc(100% - 40px) !important; /* Set to full width */
    margin-right: 0; /* Remove any right margin */
    height: auto; /* Height adjusts to content */
    margin-bottom: 15px; /* Add space between cards */
  }

  
  .entrepreneur-avatar {
    width: 30px; /* Adjust avatar size */
    height: 30px; /* Adjust avatar size */
  }

  .entrepreneur-card h2,
  .entrepreneur-card h3,
  .entrepreneur-card h4,
  .entrepreneur-card h5,
  .entrepreneur-card p {
    font-size: 14px; /* Adjust font size */
    margin: 5px 0; /* Adjust margins between text */
  }

  .entrepreneur-card h2 {
    display: inline-block;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
    font-size: 20px;
  }

  .entrepreneur-card p {
    display: inline-block;
    text-align: left;
    font-size: 14px;
  }

  .entrepreneur-contact a {
    font-size: 14px; /* Adjust link font size */
  }

  .entrepreneur-contact a img {
    width: 15px; /* Adjust icon size */
    margin-right: 5px; /* Adjust space between icon and text */
  }

  .entrepreneur-button-container {
    margin-top: 15px; /* Adjust space above the button container */
  }
}
