/* PrivacyPolicy.css */

.privacy-policy-header {
  background-color: #FFD62C;
  height: 140px;
  width: 100%; /* Full width */
}

.privacy-policy-header-container {
  max-width: 1280px; /* Adjust according to your layout */
  margin: 0 auto; /* Center the container */
  height: 100%; /* Full height of the header */
  display: flex;
  align-items: center; /* Vertically center the content */
  padding-left: 20px; /* Left padding for the title */
}

.privacy-policy-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 40px;
  color: #5E205D;
  text-align: left; /* Align to the left */
}

.privacy-policy-content {
  max-width: 1280px;
  margin: 0 auto; /* Center the container */
  padding: 30px 20px; /* Top and bottom padding with horizontal padding */
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
}

/* Add additional styles as needed */
