/* src/components/FacaParte.css */
.faca-parte {
  background-color: #FED62C !important; /* Yellow background for full width */
  padding: 40px 0; /* Vertical padding */
}

.content-area {
  max-width: 1280px; /* Maximum width for content */
  margin: 0 auto; /* Center the content horizontally */
  padding: 0 20px; /* Horizontal padding */
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* 12-column grid layout */
  column-gap: 16px; /* Space between columns */
  align-items: center; /* Center-align content vertically */
}

.title-area {
  grid-column: 1 / -1; /* Make the title area span all columns */
  text-align: center; /* Align title and paragraph to the center */
  margin-bottom: 40px; /* Space between this section and the next */
}

.faca-parte h1, .faca-parte h2 {
  font-family: 'Source Sans Pro', sans-serif;
  color: #FFFFFF; /* White color for the title */
}

.faca-parte h2 {
  color: #5E205D; /* Dark purple color for the title */
}

.faca-parte p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px !important;
  line-height: 26px !important;
  font-weight: normal;
  color: #404040; /* Dark grey color for the paragraph */
  margin-bottom: 40px !important; /* Space between paragraph and image section */
}

.individual-section {
  display: grid;
  grid-column: 1 / -1; /* Make the title area span all columns */
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 20px;
}

.f-image-container {
  grid-column: 1 / span 6; /* Image occupies first 6 columns */
}

.f-image-container img {
  width: 556px !important;
  height: auto !important;
}

.text-container {
  grid-column: 8 / span 5; /* Text occupies next 5 columns, leaving a gap */
  text-align: left; /* Align content to the left */
}

.text-container p {
  font-size: 16px; /* Smaller font size for the paragraph */
  color: #404040; /* Dark grey color */
}

.text-container p strong {
  color: #D13C8D;
}

.join-us-button {
  background-color: #4663AB;
  color: #ffffff;
  border: none;
  padding: 10px 40px !important;
  border-radius: 70px;
  text-decoration: none; /* Remove underline from link */
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
}

.join-us-button:hover {
  background-color: #D13C8D; /* Color change on hover */
}

.mirrored-section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column: 1 / -1; /* Span all columns */
  margin-top: 20px;
  margin-bottom: 20px;
}

.mirrored-section .text-container {
  grid-column: 2 / span 5; /* Text on the left side */
  text-align: left;
  width: 490px;
}

.mirrored-section .f-image-container {
  grid-column: 7 / -1; /* Image on the right side, spanning the remaining columns */
}

/* Responsive adjustments for mobile */
@media screen and (max-width: 768px) {
  .faca-parte {
    padding: 20px 10px; /* Adjusted padding */
  }

  .faca-parte h1 {
    font-size: 24px !important;
    padding: 18px !important;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    line-height: 1.2;
  }

  .faca-parte h2 {
    font-size: 16px !important;
    margin: 20px auto;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    line-height: 1.2;
  }

  .faca-parte .individual-section .f-image-container img,
  .mirrored-section .f-image-container img {
    width: 225px !important;
    height: auto;
    margin: 0 auto; /* Center the image */
  }

  .text-container {
    padding: 0 15px; /* Padding on the sides */
  }

  .faca-parte .text-container img {
    width: 49px;
    height: 49px;
    margin-right: 5px;
    float: left;
  }

  .faca-parte .text-container h2 {
    font-size: 20px !important;
    width: 130px !important;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    margin: 0 65px;
    line-height: 50px;
  }

  .text-container p {
    font-size: 16px !important;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .individual-section, .mirrored-section {
    display: block;
  }

  .mirrored-section {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .mirrored-section p {
    width: 330px !important;
    max-width: 330px !important;
  }

  .mirrored-section .f-image-container {
    order: 1 !important; /* Image first */
    margin-bottom: 30px;
  }

  .mirrored-section .text-container {
    order: 2 !important; /* Text follows the image */
    width: 330px !important;
    max-width: 330px !important;
  }

  .join-us-button {
    width: 100%; /* Full width */
    font-size: 16px;
    padding: 10px 20px; /* Adjusted padding */
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600; /* SemiBold */
    line-height: 22px;
  }

  .join-us-button-link {
    display: block;
    margin: 20px 0;
    margin-bottom: 50px;
  }
}