.newsletter-management {
  padding: 20px;
}

.newsletter-management h2 {
  margin-bottom: 20px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  margin-bottom: 0; /* Removed margin-bottom since it's inside .controls now */
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #4663AB;
  color: white;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 10px;
  text-align: left;
}

.excluir {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.excluir:hover {
  background-color: darkred;
}

.export-button {
  padding: 8px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #4663AB;
  color: white;
  transition: background-color 0.3s ease;
}

.export-button:hover {
  background-color: #333;
}
