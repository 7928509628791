/*src/components/PersonalDataForm.css*/
.content-area-minha-conta {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Ensure the background color does not apply to Menu and Footer */
.menu-container, .footer-container {
  background-color: transparent;
}

.personal-data-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* 12 columns, each 70px wide */
  column-gap: 15px; /* 15px gap between columns */
  padding: 20px;
}

.gender {
  width: 270px !important;
}

.avatar-section {
  grid-column: 1 / span 3; /* Span first three columns */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
}

.user-avatar, .user-avatar-placeholder {
  width: 159px;
  height: 159px;
  border-radius: 50%;
  background-color: #D13C8D;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 128px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  margin-bottom: 10px; /* Space between avatar and link */
}

.edit-photo-link {
  color: #4663AB;
  text-decoration: none;
}

.personal-data-section {
  grid-column: 4 / -1; /* Start from column 4 to the end */
  padding-left: 20px;
}

.section-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #5E205D;
  margin-bottom: 20px;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns layout for each row */
  gap: 15px; /* Space between grid items */
  margin-bottom: 20px;
}

.form-row.last {
  margin-bottom: -10px !important;
}

.form-row label {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #404040;
  display: block;
}

.form-row input,
.form-row select,
.form-row textarea {
  width: 250px;
  height: 50px;
  border: 1px solid #D4D4D4;
  border-radius: 10px;
  padding: 0 10px;
  margin-top: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #404040;
}

.form-row textarea {
  width: 776px;
  height: 145px;
  padding: 10px !important;
  resize: none;
}

.form-row .left {
  grid-column: 3; /* Place in the third column */
  text-align: left;
}

.personal-char-counter {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #404040;
  align-self: end;
}

.minha-conta-submit-button {
  height: 44px;
  width: 230px;
  padding: 0 25px;
  background-color: transparent;
  border: 1px solid #4663AB;
  border-radius: 50px;
  color: #4663AB;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.minha-conta-submit-button:hover {
  background-color: #4663AB;
  color: #FFFFFF;
  border: 1px solid #4663AB;
}
/* Mobile styles scoped within .content-area-minha-conta */
@media (max-width: 768px) {
  .content-area-minha-conta {
    padding: 0px; /* Adjust padding for mobile */
    max-width: 100%; /* Ensure it doesn't exceed the width of the screen */
    box-sizing: border-box; /* Include padding in the width calculation */
  }

  .content-area-minha-conta .personal-data-form,
  .content-area-minha-conta .address-form,
  .content-area-minha-conta .business-data-form {
    /* All sections should take the full available width */
    grid-template-columns: 1fr !important; /* One column layout */
    padding: 0px; /* Adjust padding for mobile */
  }

  .content-area-minha-conta .avatar-section,
  .content-area-minha-conta .personal-data-section,
  .content-area-minha-conta .address-section,
  .content-area-minha-conta .business-section {
    /* Each section should take the full available width */
    grid-column: 1 / -1 !important; /* Ensure it spans the full width */
  }

  .personal-data-section {
    padding-left: 0 !important;
    padding-right: 20px !important;
  }

  .content-area-minha-conta .form-row {
    display: flex; /* Use flexbox to allow content to fit */
    flex-direction: column; /* Stack children vertically */
    padding: 0; /* Reset any padding */
    margin: 10px 0; /* Provide some spacing top and bottom */
  }

  .content-area-minha-conta .delete-account-button {
    width: 100%; /* Button should take the full width of its parent */
    margin: 10px 0; /* Provide some spacing top and bottom */
  }

  .content-area-minha-conta .form-row label {
    /* Ensure label and input stack */
    display: flex;
    flex-direction: column;
  }

  .content-area-minha-conta .form-row input,
  .content-area-minha-conta .form-row select,
  .content-area-minha-conta .form-row textarea {
    /* Inputs should take full width */
    width: 100% !important; /* Full width for inputs */
    margin-top: 5px; /* Space between label and input */
  }

  .content-area-minha-conta .form-row textarea {
    /* Adjust height for textareas */
    height: 100px !important;
  }

  .content-area-minha-conta .minha-conta-submit-button {
    /* Full-width buttons */
    width: 100% !important;
    margin-top: 10px; /* Space above the button */
  }
  
  /* Adjust character counter to be right-aligned below the textarea */
  .content-area-minha-conta .personal-char-counter {
    text-align: right;
    margin-top: 5px;
  }

  /* Additional mobile-specific styles here */
}