/* src/components/SelectedFilters.css */
.selected-filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  color: #525252;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px; /* Adjust font size as needed */
  font-weight: bold;
  width: 1220px;
  margin-top: 50px;
  margin-left: 30px;
}

.selected-filters {
  display: flex;
  align-items: center;
}

.selected-filters a {
  text-decoration: none;
  margin-left: 10px;
}

.filter-tag {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px; /* Adjust font size as needed */
  padding: 5px 5px 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
  font-weight: normal;
  border: 1px solid #525252;
}

.clear-filters a {
  color: #4663AB;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px; /* Adjust font size as needed */
  font-weight: bold;
}

.clear-filters a:hover {
  color: #4663AB;
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px; /* Adjust font size as needed */
  font-weight: bold;
}

.clear-filters-link {
  background: none;
  border: none;
  color: #4663AB;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.filter-remove-link {
  background: none;
  border: none;
  text-decoration: none;
  margin-left: 0px;
}

.clear-filters-link:hover {
  text-decoration: underline;
}