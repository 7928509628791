/* src/components/NossoImpacto.css */
.nosso-impacto {
  text-align: center;
  padding: 40px 0;
  height: 840px;
  background-color: #FFD62C;
  background-image: url('../assets/bg-nosso-impacto.svg'); /* Update the path to your image */
  background-size: 2560px 1476px;
  background-repeat: no-repeat;
  background-position: 600px;
}

.nosso-impacto h2 {
    color: #404040;
}


.content-area {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  display: block;
  grid-column: 1 / -1 !important;
}

.title.impacto {
  grid-column: 1 / -1;
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 40px;
}

.impact-items {
  display: block;
  width: 922px;
  background-color: orange;
  margin: 0 auto;
}

.nosso-impacto .join-us-button-link {
  grid-column: 1 / -1 !important; /* Span full width */
}


.impact-items .n-image-container {
  display: block;
  width: 193px;
  margin-right: 50px;
  float: left;
  margin-bottom: 50px;
}

.impact-items .n-image-container:last-child {
  margin-right: 0px;
}

.n-image-container img {
  width: 193px;
  height: 198px;
  margin-bottom: 10px;
}

.n-image-container span {
  display: block;
  font-family: 'Source Sans Pro', sans-serif;
  color: #ffffff;
  width: 150px;
}

@media screen and (max-width: 768px) {
  .nosso-impacto {
    padding: 20px 10px;
    height: auto; /* Dynamic height for mobile */
    background-size: cover; /* Adjust background size */
    background-position: center; /* Center the background image */
  }

  .nosso-impacto h2 {
    font-size: 16px; /* Smaller font size for the subtitle */
    margin-top: 15px; /* Adjust top margin */
    margin-bottom: 30px; /* Adjust bottom margin */
    padding: 0 15px; /* Padding to prevent text from touching the edges */
  }

  .nosso-impacto .content-area {
    padding: 0 10px; /* Adjust padding for the content area */
  }

  .nosso-impacto .title.impacto h1 {
    font-size: 30px; /* Smaller font size for the title */
  }

  .nosso-impacto .impact-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for the items */
    gap: 20px; /* Space between items */
    width: auto; /* Auto width to fit content */
    background-color: transparent; /* Remove background color */
    margin: 0 auto; /* Center align the container */
    padding: 0; /* Remove padding */
  }

  .nosso-impacto .impact-items .n-image-container {
    width: auto; /* Auto width for each container */
    margin-right: 0; /* Remove right margin */
    float: none; /* Remove float */
    margin-bottom: 20px; /* Adjust bottom margin */
  }

  .nosso-impacto .n-image-container img {
    width: 100%; /* Full width of the container */
    height: auto; /* Adjust height automatically */
    max-width: 150px; /* Maximum width for the images */
    margin-bottom: 10px; /* Adjust space below the image */
  }

  .nosso-impacto .join-us-button-link {
    margin-top: 20px; /* Space above the button */
    max-width: 200px; /* Maximum width for the button */
    min-width: 200px;
    margin: 20px auto !important;
  }

  .nosso-impacto .join-us-button {
    width: auto; /* Adjust button width */
    max-width: 200px; /* Maximum width for the button */
    min-width: 200px;
  }
}

