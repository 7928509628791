/* src/pages/AdminCMS.css */
.admin-cms-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #333;
  color: white;
  padding: 15px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  padding: 10px 0;
  cursor: pointer;
}

.sidebar li:hover {
  background-color: #555;
}

.dashboard {
  flex: 1;
  padding: 20px;
}

.dashboard h1 {
  margin-bottom: 20px;
}

.dashboard-stats {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.stat h2 {
  color: #333;
  margin-bottom: 10px;
}

.stat p {
  font-size: 1.5em;
  font-weight: bold;
}
