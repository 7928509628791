/* MessageCenter.css */
.center-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 100px;
}

.center-container h1 {
  color: #ffffff;
  font-family: "Source Sans Pro";
}

.row-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.message-center-container {
  display: flex;
  width: 100%; 
  max-width: 1290px;
  height: 623px;
  background-color: #ffffff;
  border: 1px solid #D4D4D4;
  border-radius: 10px;
  margin-bottom: 20px; 
  overflow-x: hidden;
}

.message-sidebar {
  width: 280px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.message-search-box {
  margin: 20px 10px;
}



.conversation-list {
  overflow-y: auto;
  border-top: 1px solid #D4D4D4;
}

.conversation {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  border-bottom: 1px solid #D4D4D4;
}

.conversation:last-child {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  border-bottom: 0;
}

.conversation.selected {
  border-left: 5px solid #D13C8D;
}

.conversation img {
  border-radius: 50%;
  margin-right: 10px;
}

.conversation-list {
  padding: 0 !important;
}

.conversation-info h5 {
  font-family: 'Source Sans Pro';
  font-weight: bold;
  font-size: 20px;
  color: #525252;
  margin: 0;
}

.conversation-info p {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-weight: regular;
  font-size: 14px;
  color: #525252;
}

.chat-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1000px;
  height: 583px;
  padding-top: 40px;
}

.messages-container {
  overflow-y: auto;
  flex-grow: 1;
  max-width: 960px;
}

.message-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 20px;
}


.chat-message {
  display: flex;
  align-items: center; /* Align items vertically */
  width: auto;
  border-radius: 20px;
  padding: 15px;
  margin-left: 20px;
  margin-bottom: 10px;
  max-width: 60%;
  align-self: flex-start; /* Default alignment for messages */
  background-color: #F4F4F5;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 20px; /* No radius applied here */
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #525252;
}

.message-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Left message wrapper */
.message-wrapper.left .message-avatar {
  margin-right: 10px; /* Space between avatar and message */
}

/* Right message wrapper */
.message-wrapper.right {
  justify-content: flex-end; /* Aligns the message to the right */
}

.chat-message.right {
  float: right;
  margin-right: 20px;
  background-color: #FFF1B7;
  color: #525252;
  max-width: 60%;
  align-self: flex-end; /* Aligns the message to the right */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0; /* No radius applied here */
  justify-content: flex-end; /* Align text and avatar to the right */
  text-align: right; /* Align the text to the right */
}

.chat-message.right .message-avatar {
  order: 1; /* Avatar goes after the text in a right message */
  margin-left: 10px; /* Space between message and avatar */
  margin-right: 0; /* Reset the right margin */
}

.message-input {
  width: calc(100% - 90px); /* Subtract the padding from the width */
  margin-left: 15px;
  padding: 10px 20px; /* Same padding as .chat-area for consistency */
  background-color: white;
  border: 1px solid #D4D4D4;
  border-radius: 10px;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  margin-bottom: 0px;
}

.message-input input:focus {
  outline: none !important;
}

.message-input::placeholder {
  color: #A3A3A3;
}

.message-input input {
  width: 98%;
  padding: 10px;
  border: none;
  border-radius: 0;
}

.message-avatar {
  border-radius: 50%;
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
  margin-right: 10px; /* Space between avatar and message */
}

.message-avatar-initial {
  width: 35px;
  height: 35px;
  background-color: #D13C8D;
  border-radius: 50%;
  color: #FFFFFF;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 35px;
}

/* Add to your MessageCenter.css */

.message-input button {
  border: 1px solid #D13C8D;
  border-radius: 50px;
  background-color: transparent;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px; /* Space between input and button */
  font-size: 16px; /* Adjust size as needed */
  color: #D13C8D; /* Button text color */
}

.message-input {
  display: flex;
  align-items: center; /* Aligns input and button */
}

@media only screen and (max-width: 600px) {
  .message-sidebar {
    width: 100%;
    border-right: 0;
    overflow-y: auto;
  }
  .chat-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 583px;
    padding-top: 20px;
  }

  .message-input {
    width: calc(100% - 40px); /* Subtract the padding from the width */
    margin-left: 15px;
    padding: 10px 20px; /* Same padding as .chat-area for consistency */
    background-color: white;
    border: 1px solid #D4D4D4;
    border-radius: 10px;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    margin-bottom: 15px;
  }

}
