/* src/components/NossosValores.css */
.nossos-valores {
  text-align: center;
  padding: 40px 0;
  background-color: #5D215C;
  background-image: url('../assets/bg-nossos-valores.svg'); /* Update the path to your image */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: -520px; /* Adjust as per your design requirements */
}

.nossos-valores .content-area {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 16px; /* Space between columns */
}

.title {
  grid-column: 1 / -1;
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 40px;
  margin-top: 20px;
}

.title h1 {
  color: #FFFFFF;
}

.first-line {
  display: grid;
  grid-column: 1 / -1; /* Span full width */
  grid-template-columns: repeat(12, 1fr); /* Maintain 12-column layout */
  justify-items: center;
  align-items: center;
  margin-bottom: 80px;
}

/* Target the first image and place it starting from column 2 */
.first-line .image-container:nth-child(1) {
  grid-column: 2 / 4; /* Span from column 2 to 4 */
}

/* Target the second image and place it with a gap after the first image */
.first-line .image-container:nth-child(2) {
  grid-column: 5 / 7; /* Span from column 5 to 7 */
}

/* Target the third image and place it with a gap after the second image */
.first-line .image-container:nth-child(3) {
  grid-column: 8 / 10; /* Span from column 8 to 10 */
}

/* Target the fourth image and place it with a gap after the third image */
.first-line .image-container:nth-child(4) {
  grid-column: 11 / 12; /* Span from column 11 to 13 */
}

.second-line {
  display: grid;
  grid-column: 1 / -1; /* Span full width */
  grid-template-columns: repeat(12, 1fr); /* Maintain 12-column layout */
  justify-items: center;
  align-items: center;
  margin-bottom: 80px;
}

.second-line .image-container:nth-child(1) {
  grid-column: 4 / 4;
}

.second-line .image-container:nth-child(2) {
  grid-column: 6 / 8; 
}

.second-line .image-container:nth-child(3) {
  grid-column: 8 / 12;
}

.image-container img {
  width: 80px !important;
  height: 80px !important;
}

.first-line,
.second-line {
  display: grid;
  grid-column: 1 / -1; /* Span full width */
  grid-template-columns: repeat(12, 1fr); /* Maintain 12-column layout */
  justify-items: center;
  align-items: center;
}

.first-line .image-container,
.second-line .image-container {
  grid-column: span 2; /* Each image spans 2 columns */
}

.image-container img {
  width: 100px; /* Adjust as necessary */
  height: auto;
  margin-bottom: 10px; /* Space between image and description */
}

.image-container span {
  display: block; /* Ensure the description is on its own line */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  color: #ffffff;
  width: 150px;
}

.valores h1 {
  color: #5D215C !important;
}

@media screen and (max-width: 768px) {
  .nossos-valores {
    background-position: -400px 30% !important;
    background-size: 140%;
    padding: 20px 0 !important;
  }

  .nossos-valores .content-area {
    padding: 0 10px !important; /* Adjusted padding for smaller screens */
    display: block !important; /* Switch to block layout for stacking */
  }

  .nossos-valores .title h1 {
    font-size: 24px !important; /* Smaller font size for mobile */
    text-align: center !important; /* Centralize the title */
    margin-bottom: 20px !important; /* Space below the title */
  }

  .nossos-valores .first-line,
  .nossos-valores .second-line {
    display: flex !important; /* Use flexbox for a flexible layout */
    flex-wrap: wrap !important; /* Allow items to wrap to the next line */
    justify-content: space-around !important; /* Space out items evenly */
    margin-bottom: 20px !important; /* Space between the two lines of icons */
  }

  .nossos-valores .image-container {
    flex: 0 0 calc(33.333% - 20px) !important; /* Give each item one-third of the space minus margin */
    display: flex !important; /* Use flexbox for alignment */
    flex-direction: column !important; /* Stack image and text vertically */
    align-items: center !important; /* Center items horizontally */
    justify-content: center !important; /* Center items vertically */
    margin-bottom: 20px !important; /* Space between each set */
  }

  .nossos-valores .image-container img {
    width: 60px !important; /* Smaller image size for mobile */
    height: 60px !important; /* Maintain aspect ratio */
    margin-bottom: 8px !important; /* Reduced space between image and description */
  }

  .nossos-valores .image-container span {
    font-size: 14px !important; /* Smaller font size for descriptions */
    color: #ffffff !important; /* White text for better readability */
    text-align: center !important; /* Center-align the text */
  }

  /* Center the last item on the second line */
  .nossos-valores .second-line .image-container:last-child {
    flex: 0 0 100% !important; /* Take full width of the line */
    margin-top: 30px;
    margin-bottom: 60px !important;
  }
}
