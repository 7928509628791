@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, button, p, label {
  font-family: 'Source Sans Pro', sans-serif;
}

p {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

h3 {
  font-weight: bold;
  font-size: 20px;
}

h5 {
  font-weight: bold;
}

.text-purple-700 {
  color: #5E205D;
}

.text-blue-800 {
  color: #4663AB;
}

.text-orange-700 {
  color: #EE7911;
}

.h-450 {
  height: 450px;
}

.text-blue-800 {
  color: #4663AB;
}

.bg-blue-refugia {
  color: #ffffff !important;
  background-color: #4663AB;
}

.bg-purple-refugia {
  border-top: 1px solid #5E205D;
}

.text-white {
  color: #ffffff;
}

.button-solid {
  color: #ffffff !important;
}

.text-pink {
  color: #D13C8D;
}

.blog-search-button {
  background: url('assets/input-search.png') no-repeat center center;
  width: 52px;
  height: 52px;
  border: none;
  cursor: pointer;
  background-size: 52px 52px; /* Ensure the image covers the entire button area */
}
.blog-search-button:hover {
  background-color: #D13C8D; /* Change background color on hover */
}

.select-container {
  position: relative;
}

.select-container::after {
  content: '\2304'; /* Unicode character for a downward arrow */
  position: absolute;
  right: 1rem; /* Adjust as needed */
  top: 40%;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the arrow doesn't interfere with select */
  color: #000000; /* Color of your choice */
  font-size: 1rem; /* Adjust size as needed */
}