/* src/pages/BlogPost.css */
.blogpost-page-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
}

.blogpost-content-column {
    flex: 3;
    margin-right: 20px;
    padding: 50px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    background-color: #fff;
    border-radius: 8px;
}

.blogpost-page-container .blog-sidebar {
    flex: 1;
    width: 283px !important;
    max-width: 283px !important;
    border: 0;
    margin-top: 80px;
}

.post-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1em; /* Adjust as needed */
}

.back-to-list {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  color: #4663AB; /* Adjust color as needed */
  text-decoration: none;
  margin-bottom: .5em; /* Adjust as needed */
  padding-bottom: 20px;
}

.post-category-date {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Adjust width as needed */
  margin-bottom: 1em;
  margin-top: 20px;
/* Adjust as needed */
}

.post-category {
    background-color: #D13C8D; /* Adjust color as needed /
    color: white;
    border-radius: 5px;
    padding: 0.2em 0.6em;
    font-size: 0.8em; / Adjust font size as needed */
}

.post-date {
    font-family: 'Source Sans Pro';
    font-size: 16px;
}

/* ... (rest of your styles) */

@media (max-width: 1024px) {
    .blogpost-page-container {
        flex-direction: column;
    }

    .blogpost-content-column {
        margin-right: 0;
    }

    .blogpost-sidebar {
        width: 100%;
    }

    .blog-post-column {
      padding: 10px;
      float: left;
    }

    .blog-post-column img {
        max-width: 100%;
    }
}

/* Add any other specific styles for the BlogPost page here */

