/* src/components/NossosResultados.css */
.nossos-resultados {
  background-color: #2D713B; /* Green background */
  color: #ffffff; /* White text */
  padding: 40px 0; /* Vertical padding */
  background-image: url('../assets/bg-nossos-resultados.svg'); /* Update the path to your image */
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: 70% 70%;
  height: 650px;
}

.content-area {
  max-width: 1280px;
  margin: 0 auto; /* Center the content */
  padding: 0 20px; /* Horizontal padding */
  text-align: center; /* Center-align text */
}

.title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 40px; /* Space below the title */
}

.results-grid {
  display: grid;
  grid-column: 1 / -1; /* Make the title area span all columns */
  grid-template-columns: repeat(2, 1fr); /* Two columns for the results */
  column-gap: 16px; /* Space between columns */
  align-items: center; /* Center-align icons and descriptions */
}

.result-column {
  display: flex;
  flex-direction: column; /* Stack icon, subtitle, and text vertically */
  align-items: center; /* Center-align content within each column */
  height: 285px;
}

.result-column:first-child {
  display: flex;
  flex-direction: column; /* Stack icon, subtitle, and text vertically */
  align-items: flex-end; /* Center-align content within each column */
  padding-right: 80px;
  height: 285px;
}

.result-column:last-child {
  display: flex;
  flex-direction: column; /* Stack icon, subtitle, and text vertically */
  align-items: flex-start; /* Center-align content within each column */
  padding-left: 80px;
  height: 285px;
}

.result-column p {
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 33px;
	font-weight: bold;
	margin: 0;
	padding: 0;
}

.result-column img {
  width: auto; /* Adjust as needed */
  height: 94px;
}

.subtitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 95px; /* Larger font size for impact */
  font-weight: bold;
  margin: -15px 0 -10px 0;
  padding: 0;
}

.block-content {
  display: flex;
  flex-direction: column; /* Stack icon, subtitle, and text vertically */
  align-items: center; /* Center-align content within each column */

}

@media screen and (max-width: 768px) {
  .nossos-resultados {
    padding: 20px 10px !important; /* Adjusted padding for smaller screens */
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: 70% 50%;
    max-height: 450px;
  }


  .nossos-resultados .results-grid {
    grid-template-columns: repeat(2, 1fr); /* Maintain a two-column layout */
    gap: 10px; /* Reduce the gap between grid items */
    justify-content: center; /* Center the grid items horizontally */
    align-items: center; /* Center the grid items vertically */
  }

  .nossos-resultados .result-column img {
    width: 57px !important; /* Adjusted icon size */
    height: 57px !important;
  }

  .nossos-resultados .subtitle {
    font-size: 45px !important; /* Adjusted font size for subtitle */
    font-weight: bold !important; /* Bold font style */
    margin: -10px 0 0 !important; /* Adjusted margin for subtitle */
    font-family: 'Source Sans Pro', sans-serif !important;
  }

  .nossos-resultados .result-column p {
    font-size: 15px !important; /* Adjusted font size for descriptions */
    line-height: 20px;
    font-weight: bold !important; /* Bold font style */
    font-family: 'Source Sans Pro', sans-serif !important;
  }

  .nossos-resultados .result-column {
    justify-content: center; /* Center the grid items horizontally */
    align-items: center; /* Center the grid items vertically */
    padding: 0;
    height: 165px;
  }
}