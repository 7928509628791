/* src/components/MainHero.css */
.main-hero-container {
  background-color: #5E205D;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 557px;
}

.main-hero-content-area {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 16px;
  max-width: 1280px;
  width: 100%;
  padding: 0;
  height: 557px;
}

.main-hero-left-block {
  grid-column: 1 / span 5;
  padding-top: 60px!important;
}


.main-hero-right-block {
  grid-column: 6 / span 7;
  margin: 0;
  padding: 0;
}

.main-hero-right-block img {
  height: 557px;
  width: auto;
}

.main-hero-left-block h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
  width: 462px;
}

.main-hero-left-block p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  margin-bottom: 40px;
  width: 462px;
}

.main-hero-left-block p strong {
  color: #FFD62C;
}

.join-us-button {
  background-color: #4663AB;
  color: #ffffff;
  border: none;
  padding: 10px 40px !important;
  height: 49px;
  border-radius: 70px;
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px !important;
}

.join-us-button:hover {
  background-color: #D13C8D;
}

@media screen and (max-width: 768px) {
  .main-hero-container {
    padding-top: 60px;
    padding-left: 25px;
    padding-right: 25px;
    height: 440px;
    background-image: url('/src/assets/bg-main-hero-mobile.svg'); /* Add mobile-specific background image */
    background-size: cover; /* Cover the full height */
    background-position: right; /* Align to the right */
    background-repeat: no-repeat; /* No repeat */
  }

  .main-hero-content-area,
  .main-hero-left-block {
    grid-column: 1 / -1; /* Ensure the content spans the full width */
  }

  .main-hero-right-block {
    display: none; /* Hide the right block on mobile */
  }

  .main-hero-left-block h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 24px; /* Font size of 24px */
    font-weight: bold; /* Bold font weight */
    line-height: 32px; /* Line height of 32px */
    margin-bottom: 25px; /* Margin bottom of 25px */
    min-width: 308px; /* Minimum width of 308px */
    max-width: 308px; /* Maximum width of 308px */
  }

  .main-hero-nossa-missao {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px; /* Font size of 16px */
    margin-bottom: 24px; /* Margin bottom of 24px */
    min-width: 270px; /* Minimum width of 270px */
    max-width: 270px; /* Maximum width of 270px */
  }

  .join-us-button-link {
    display: block; /* Make the link a block to fit the button inside */
    height: 42px; /* Height of 42px */
    margin: auto; /* Center the button */
  }

  .join-us-button {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px; /* Font size of 16px */
    font-weight: 600; /* SemiBold font weight */
    line-height: 22px; /* Line height of 22px */
    padding: 10px; /* Adjusted padding */
    width: 100%; /* Full width of its container */
    height: 42px; /* Height of 42px */
    box-sizing: border-box; /* Include padding and border in the button's dimensions */
  }
}