@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

.bg-blue-refugia, .text-blue-800, .border-blue-800 {
  color: #4663AB;
}

.border-blue-800 {
  border-color: #4663AB;
}

body {
  background-color: #F4F4F5;
}

.text-purple {
  color: #5E205D;
}

/* Menu Container Styles */
.menu-outer-container {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 100%; /* Full width */
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}

.menu-container {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
  height: 128px;
  margin: 0 auto;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 10px;
}

.menu-close-button {
  display: none;
}

.menu-logo {
  width: 143px;
  height: 80px;
  margin-right: 280px; /* Adjust as needed */
}

.menu-links {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribute space evenly */
  flex-grow: 1; /* Allow the container to grow to fill the space */
  margin-left: -200px; /* Optional: Adjust as needed for spacing from the logo */
}

.user-avatar-menu {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Makes the image round */
  object-fit: cover; /* Ensures the image covers the area properly */
}

.bg-yellow::after {
  background-color: #FED62C;
  transform: skewY(-3deg);
  transform-origin: top left;
  border-radius: 5px;
}

.bg-purple::after {
  background-color: #5E205D;
  transform: skewY(-1.5deg);
  transform-origin: top left;
  border-radius: 5px;
}

.bg-orange::after {
  background-color: #EE7913;
  transform: skewY(-1.5deg);
  transform-origin: top left;
  border-radius: 5px;
}

.bg-blue::after {
  background-color: #4663AB;
  transform: skewY(-1.5deg);
  transform-origin: top left;
  border-radius: 5px;
}

.bg-grey::after {
  background-color: #EDEDED;
  transform: skewY(-1.5deg);
  transform-origin: top left;
  border-radius: 5px;
}

.bg-pink::after {
  background-color: #D13C8D;
  transform: skewY(-1.5deg);
  transform-origin: top left;
  border-radius: 5px;
}

.txt-purple {
  color: #5E205D;
}

.tag {
  text-decoration: none;
  transition: background-color 0.3s;
  display: inline-block;
  white-space: nowrap;
  position: relative; /* This is crucial for positioning the pseudo-element */
  overflow: hidden; /* Ensures the pseudo-element does not overflow the link's boundaries */
  top: 15px;
  z-index: 0; /* Set the base z-index */
}



.tag::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: transform 0.3s;
}

.menu-link {
  color: #D13C8D;
  text-decoration: none;
  padding: 5px 6px 6px 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
  display: inline-block;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;
  position: relative; /* This is crucial for positioning the pseudo-element */
  overflow: hidden; /* Ensures the pseudo-element does not overflow the link's boundaries */
  z-index: 0; /* Set the base z-index */
}

.menu-links :first-child {
  margin-left: 0;
}

.menu-link:last-child {
  margin-right: 0; /* No margin for last item */
}

.menu-link:hover::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #FFD62C;
  z-index: -1;
  border-radius: 5px;
  transform: skewY(-3deg);
  transform-origin: top left;
  transition: transform 0.3s;
}

.menu-link-separator {
  height: 26px; /* Height of the separator */
  width: 1px; /* Width of the separator */
  background-color: #D4D4D4; /* Color of the separator */
}

.menu-link.login-link::before {
  content: '';
  display: inline-block;
  width: 18px; /* Width of the icon */
  height: 18px; /* Height of the icon */
  background-image: url('assets/ico-login.svg'); /* Path to the icon */
  background-size: cover; /* Ensure the icon covers the area */
  margin-right: 5px; /* Space between the icon and the text */
  vertical-align: middle; /* Aligns the icon with the text */
}

.menu-link.join-us-link:hover::after {
  display: none; /* Disables the hover effect */
}


.menu-link.join-us-link {
  background-color: #4663AB;
  width: 135px;
  height: 44px;
  line-height: 44px; /* To vertically center the text */
  text-align: center; /* Center the text horizontally */
  border-radius: 70px;
  color: white; /* Text color */
  display: inline-flex; /* To maintain the shape and center the text */
  justify-content: center; /* Align text horizontally */
  align-items: center; /* Align text vertically */
  transition: background-color 0.3s;
}

.menu-link.join-us-link:hover {
  background-color: #D13C8D; /* Color change on hover */
}

.menu-link.post-item-link:hover::after {
  display: none; /* Disables the hover effect */
}

.menu-link.post-item-link {
  background-color: #ffffff;
  border: 1px solid #4663AB;
  color: #4663AB;
  width: 194px;
  height: 32px;
  line-height: 32px; /* To vertically center the text */
  text-align: center; /* Center the text horizontally */
  border-radius: 70px;
  display: inline-flex; /* To maintain the shape and center the text */
  justify-content: center; /* Align text horizontally */
  align-items: center; /* Align text vertically */
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.menu-link.post-item-link:hover {
  background-color: #D13C8D;
  border-color: #D13C8D;
  color: #ffffff;
}

.menu-link.message-link:hover::after {
  display: none; /* Disables the hover effect */
}

.menu-link.message-link {
  padding: 0;
  background-image: url('assets/ico-message.svg'); /* Path to the icon */
  background-size: 32px 32px; /* Size of the icon */
  width: 32px; /* Width of the icon */
  height: 32px; /* Height of the icon */
  display: inline-block; /* Display as a block element */
  vertical-align: middle; /* Align with other menu items */
}

.menu-link.message-link:hover {
  filter: brightness(0) saturate(100%) invert(40%) sepia(71%) saturate(2900%) hue-rotate(303deg) brightness(85%) contrast(91%);
}

.user-submenu-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative; /* Keep it relative to position the submenu absolutely */
}

.user-expanded-submenu {
  position: absolute;
  top: -11px; /* Position from the top of the viewport */
  right: -20px; /* Adjusted position to the right of the arrow */
  width: 180px;
  border: 1px solid #D4D4D4;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  padding: 10px 10px;
  z-index: 101; /* Ensure it's above the user arrow */
}

.submenu-link {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #D13C8D;
  text-decoration: none; /* Removes default underline */
  text-align: left; 
  box-sizing: border-box; /* Include padding and border in element's total width */
  padding: 5px 0 10px 0; /* Remove left and right padding */
  border-bottom: 1px solid #D4D4D4; /* Solid line below each link */
  width: 100%; /* Ensure the link takes full width of its container */
  display: block; /* Each link takes its own line */
  margin-bottom: 5px; /* Space between the item and the line */
}

.submenu-link:hover {
  text-decoration: underline; /* Removes default underline */
}

.submenu-link:first-child {
  margin-top: 40px;
}

.submenu-link:last-child {
  border-bottom: none; /* No border for the last link */
  margin-bottom: 0;
  padding-bottom: 5px;
}

.user-initial-container.move-right {
  transform: translateX(-82px);
  transition: transform 0.3s ease-in-out;
}

.user-greeting.fade-in {
  opacity: 1; /* Fade in when submenu is open */
  margin-left: 50px;
}

.user-greeting {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600; /* Semibold */
  color: #D13C8D;
  margin: 10px; /* Spacing from the top of the box */
  opacity: 0; /* Initial state - invisible */
  transition: opacity 0.3s ease-in-out; /* Transition for fade in/out */
}

.user-initial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;
}

.user-initial {
  line-height: 35px;
}

.user-arrow {
  border-right: 1px solid #D13C8D;
  border-bottom: 1px solid #D13C8D;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  margin-left: 10px; /* Reduced space between initial container and arrow */
  display: inline-block;
  vertical-align: middle;
  transition: transform 0.3s;
  z-index: 102;
}

.user-arrow.up {
  transform: rotate(-135deg); /* Rotate arrow to face up */
}

/*footer*/

.footer-outer-container {
  width: 100%; /* Full width of the viewport */
  background-color: #F8F8F8; /* Footer background color */
}

/* Keep the existing .footer styles */
.footer {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 80px 20px;
  font-family: 'Source Sans Pro', sans-serif; /* Set the font family */
  font-weight: lighter;
  font-size: 16px; /* Set font size */
  line-height: 24px; /* Set line height */
}

.footer a {
  color: #D13C8D; /* Set link color */
  font-weight: bold; /* Make links bold */
  text-decoration: none; /* Optional: removes underline from links */
}

.footer a:hover {
  text-decoration: underline; /* Optional: underline on hover */
}

.footer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-logo {
  flex-basis: 33.333%; /* Equivalent to 4 columns */
  align-items: flex-start; /* Aligns children to the start horizontally */
  justify-content: flex-start; /* Aligns children to the start vertically */
  text-align: left; /* Aligns text to the left */
}

.footer-logo, .footer-contact {
  flex-basis: 33.333%; /* Equivalent to 4 columns */
}

.footer-gap {
  flex-basis: 25%; /* Equivalent to 3 columns */
}

.footer-social {
  flex-basis: 8.333%; /* Equivalent to 1 column */
  align-items: center;
}

.footer-logo img {
  max-width: 100%;
  height: 69px;
  left: 0;
}

.footer-social svg {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.footer .footer-contact h4 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #5E205D;
  margin-bottom: 0;
}

.social-icon {
  display: block;
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-bottom: 10px; /* Space between icons */
  text-indent: -9999px;
}

.facebook-icon {
  background-image: url('assets/ico-facebook.svg');
}

.instagram-icon {
  background-image: url('assets/ico-instagram.svg');
}

.twitter-icon {
  background-image: url('assets/ico-twitter.svg');
}

.linkedin-icon {
  background-image: url('assets/ico-linkedin.svg');
}

.social-icon:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(6383%) hue-rotate(322deg) brightness(93%) contrast(89%);
}

.nossa-missao {
  font-size: 18px !important;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5em;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  body, html {
    overflow-x: hidden !important; /* This will hide any horizontal overflow */
  }
/*MENU*/

  .menu-container {
    flex-wrap: wrap;
  }

  .menu-container a {
    max-width: 240px;
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
  }

  .menu-links {
    /* Transform the menu off-screen when not in use */
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%; /* Adjust width as necessary */
    max-width: 300px; /* Set a max-width for the menu */
    height: 100%;
    background: white;
    z-index: 1500; /* Ensure it's above other content */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    padding-left: 40px;
    justify-content: left;
    box-shadow: none; 
  }

  .menu-links.open {
    /* Slide in when the menu is open */
    transform: translateX(0);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  }

  .menu-close-button {
    align-self: flex-end; /* Align the close button to the right */
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
  }

  .menu-link {
    margin: 10px 0;
    padding: 10px 0; /* Add padding for touch targets */
  }

  .menu-link-separator {
    height: 1px; /* Make separator horizontal */
    width: 100%; /* Span full width of menu */
    background-color: #D4D4D4; /* Separator color */
    margin: 10px 0; /* Add vertical margin for spacing */
    display: block; /* Ensure it's displayed as a block-level element */
  }

  /* Logged-in user submenu adjustments */
  .user-submenu-container {
    order: 2; /* Ensures user submenu is placed after the other menu items */
  }

  .user-expanded-submenu {
    display: flex; /* Always display the submenu */
    flex-direction: column; /* Stack the submenu items */
    position: static; /* Use the natural flow, no absolute positioning */
    box-shadow: none; /* No shadow needed as it's always visible */
    padding: 0; /* Reset padding */
    border-top: 1px solid #ececec; /* Separator from the main menu */
    margin-top: 10px; /* Some space from the main menu */
    width: auto; /* Let it take the natural width */
    background-color: transparent; /* Match the menu background */
    z-index: 1; /* Normal stacking order */
  }

  .user-submenu-container.open .user-expanded-submenu {
    display: flex; /* Display the submenu when the state is 'open' */
  }

  .submenu-link {
    padding: 10px 20px; /* Sufficient padding for touch targets */
    width: 100%; /* Full width of the submenu */
    text-align: left; /* Align text to the left */
    color: #333; /* Match the color with other links */
    border-bottom: 1px solid #ececec; /* Separator between submenu items */
    background-color: #ffffff; /* Background color for submenu links */
  }

  .submenu-link:last-child {
    border-bottom: none; /* No border for the last submenu item */
  }

  /* Adjust the user initial container and avatar for the mobile view */
  .user-initial-container {
    width: 35px; /* Adjust size if needed */
    height: 35px;
    margin-right: 10px; /* Space between the avatar and the menu links */
    order: 1; /* Place before the menu links */
  }

  /* Hide the arrow indicating a dropdown, as this doesn't apply on mobile */
  .user-arrow {
    display: none;
  }
  
  /* Position the publish popup link correctly within the flow of menu items */
  .menu-link.post-item-link {
    order: 3; /* Position after the user submenu */
  }

  /* Adjust footer styles for mobile */
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  .footer-gap {
    display: none;
  }

/*FOOTER*/

  .footer {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
  }

  .footer-section {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-section.footer-logo {
    order: 1;
  }

  .footer-section.footer-contact {
    order: 2;
  }

  .footer-section.footer-social {
    order: 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .social-icon {
    margin: 0 10px;
  }

  .footer-logo img {
    margin-bottom: 10px;
  }

}
