.mural-management {
  width: calc(100% - 40px);
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
}

.mural-management h2 {
  margin-bottom: 30px;
  color: #333;
  font-size: 24px;
}

.mural-management table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.mural-management th,
.mural-management td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}


.mural-management td:nth-child(1) {
  min-width: 50% !important;
}

.mural-management td:last-child {
  width: 15% !important;
}

.mural-management th {
  background-color: #4663AB;
  color: white;
}

.mural-management tr:nth-child(even) {
  background-color: #f2f2f2;
}

.mural-management tr:hover {
  background-color: #ddd;
}

.mural-management button {
  padding: 8px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #4663AB;
  color: white;
  transition: background-color 0.3s ease;
}

.mural-management button:hover {
  background-color: #333;
}

.edit-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.voltar {
  margin-top: 15px;
  background-color: gray !important;
}

.edit-form input,
.edit-form textarea {
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.edit-form textarea {
  height: 100px;
  resize: vertical;
}

.edit-form button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.edit-form button:hover {
  background-color: #45a049;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .mural-management {
    padding: 10px;
  }
}
