/*src/components/BlogCardSkeleton.css*/
.blog-card-skeleton {
  width: 325px;
  height: 435px;
  border-radius: 5px;
  background-color: #eee;
  overflow: hidden;
  margin-bottom: 20px;
}

.skeleton-media {
  height: 200px;
  background-color: #ddd;
}

.skeleton-content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skeleton-line {
  height: 20px;
  background-color: #ccc;
  border-radius: 4px;
}

.skeleton-line.wide {
  width: 80%;
}

.skeleton-line.small {
  width: 30%;
}