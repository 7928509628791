/* PrivacyPolicySkeleton.css */

.privacy-policy-skeleton .skeleton-header {
  background-color: #E0E0E0;
  height: 30px;
  width: 60%;
  margin-bottom: 15px;
}

.privacy-policy-skeleton .skeleton-content .skeleton-paragraph {
  background-color: #E0E0E0;
  height: 15px;
  margin-bottom: 10px;
  width: 100%;
}

/* You can add more styles to better match the layout of your PrivacyPolicy page */
