/* src/components/SobreRedeRefugia.css */
.sobre-rede-refugia {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  background-color: #EE7913;
  background-image: url('../assets/foto-sobre.png');
  background-size: 747px 1043px;
  background-repeat: no-repeat;
  background-position: calc(50% + 500px);
  color: #ffffff;
}

.sobre-content-area {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 16px;
  max-width: 1280px;
  width: 100%;
}

.sobre-left-block {
  grid-column: 1 / span 7;
  padding-right: 20%;
  text-align: left;
}

.sobre-left-block h1,
.sobre-left-block h2,
.sobre-left-block h3 {
  font-family: 'Source Sans Pro', sans-serif;
}

.sobre-left-block h3 {
  margin-top: 60px;
  margin-bottom: 40px;
}

.sobre-left-block p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
}

.sobre-left-block p:nth-child(10) {
  padding-bottom: 40px !important;
}

@media screen and (max-width: 768px) {
  .sobre-rede-refugia {
    background-color: #EE7913;
    padding: 20px; /* Adjusted padding */
    padding-left: 40px;
  }

  .sobre-content-area {
    display: block; /* Stack content vertically */
  }

  .sobre-left-block {
    text-align: left; /* Align text to the left */
    min-width: 360px;
    max-width: 360px;
    min-height: fit-content;
  }

  .sobre-left-block p {
    margin-top: 0px;
  }

  .sobre-left-block p:nth-child(2) {
    margin-top: 100px !important;
    background-color: fuchsia;
  }

  .sobre-left-block h1 {
    font-size: 24px; /* Adjusted font size */
    font-weight: bold;
    margin-bottom: 20px; /* Adjusted bottom margin */
  }

  .sobre-left-block h2 {
    font-size: 20px; /* Adjusted font size */
    font-weight: bold;
    line-height: 24px;
  }

  .sobre-left-block h3 {
    font-size: 16px; /* Adjusted font size */
    font-weight: 600; /* Semi-bold */
    line-height: 24px;
    margin-bottom: 360px; /* Space for the background image */
    position: relative; /* Position relative to allow absolute positioning of the image */
  }



  .sobre-left-block .join-us-button-link {
    text-align: left; /* Align button to the left */
    display: inline-block; /* Fit content width */
    max-width: 230px !important;
    min-width: 230px !important;
  }

  .sobre-left-block .join-us-button {
    padding: 10px 20px; /* Adjusted padding */
    margin-top: 20px; /* Space after the last paragraph */
    max-width: 230px !important;
    min-width: 230px !important;
    margin-top: -40px;
    margin-bottom: 80px;
  }

  .sobre-rede-refugia {
    background-image: url('../assets/foto-sobre.png') !important;
    background-size: 373px 520px !important;
    background-repeat: no-repeat !important;
    background-position: 50% 35% !important;
    color: #ffffff !important;
  }

  .second-paragraph {
    margin-top: 350px !important;
  }

}
